import {
  useStore
} from 'vuex';
import {
  getDownloadUrl
} from '@/service/index.js';
import {
  alertMessage
} from '@/assets/js/tool.js';
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage
} from '@/assets/js/storage.js';

export default function useDownLoad() {
  const store = useStore();

  async function download(data) {
    //先清除storage
    clearLocalStorage('download');
    clearLocalStorage('toPage');

    let result = await getDownloadUrl(data);
    if (result.status === 0) {
      //_self 本窗口打开
      window.open(result.data, '_self');
    } else if (result.status === -102) {
      setLocalStorage('download', {
        id: data.id,
        platform: data.platform,
        downloadFileType: data.downloadFileType,
      });
      //打开登录dialog
      store.commit('setShowLogin', true);
    } else {
      let lang = getLocalStorage('lang') || 'en';
      let langText = lang === 'en' ? 'Download failed！' : '下载失败！';
      let text = result ? result.msg || langText : langText;
      alertMessage('error', text);
    }
  };

  return {
    download
  };
};