import { ref, watch, nextTick } from 'vue';
import { useI18n } from 'vue-i18n';
import { ArrowRight } from '@element-plus/icons-vue';
import { getLocalStorage } from '@/assets/js/storage.js';
import { getUUid, alertMessage } from '@/assets/js/tool.js';
import useDownLoad from '@/assets/js/use-download.js';
export default {
  props: {
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    isProduct: Boolean
  },
  components: {
    ArrowRight
  },
  setup(props) {
    //data
    const {
      t
    } = useI18n();
    const timer = ref(false);
    const list = ref([]);
    list.value = props.options;
    const dialogVisible = ref(false);
    const itemOld = ref(null);
    const itemNow = ref(null);

    //ref
    const descriptionContentRef = ref(null);

    //watch
    watch(() => props.options, async () => {
      list.value = props.options;
      await nextTick();
      initTableHeight();
    });

    //use-upload
    const {
      download
    } = useDownLoad();

    //function
    /**初始化表格每一行高度*/
    function initTableHeight() {
      let oldHeight = 120;
      let descriptionContentRefVal = descriptionContentRef.value;
      for (let i in descriptionContentRefVal) {
        let $descriptionContent = descriptionContentRefVal[i];
        let p = $descriptionContent.querySelector('p');
        //高度减去上下padding
        let fatherHeight = oldHeight - 20 - 20;
        let sonHeight = p.offsetHeight;
        list.value[i].nowHeight = oldHeight;
        if (sonHeight > fatherHeight) {
          list.value[i].maxHeight = sonHeight + 40;
          list.value[i].ellipsis = true;
        }
      }
    }

    /**获取文本*/
    function getHtml(text) {
      return text.replace(/\n/g, '<br/>');
    }

    /**点击更多*/
    function ellipsisFn(index) {
      list.value[index].ellipsis = false;
      list.value[index].nowHeight = list.value[index].maxHeight || 120;
    }

    /**点击下载*/
    function clickDownLoad(item) {
      if (timer.value) clearTimeout(timer.value);
      timer.value = setTimeout(() => {
        let data = getData(item);
        //固件下载2.1.2特殊逻辑
        if (item.version === '2.1.2' && item.productModel) {
          let name = item.productModel.name;
          dialogVisible.value = true;
          itemNow.value = item;
          itemOld.value = props.options.find(itm => itm.version === '2.1.1' && itm.productModel.name === name);
        } else {
          download(data);
        }
      }, 200);
    }

    /**获取参数*/
    function getData(item) {
      let sid = getLocalStorage('sid') || getUUid();
      let data = {
        sid: sid,
        id: item.id,
        platform: !props.isProduct ? item.platform : 'PRODUCT',
        downloadFileType: !props.isProduct ? item.downloadFileType : 'PRODUCT'
      };
      return data;
    }

    /**是否继续下载*/
    function downloadContinue(isContinue) {
      dialogVisible.value = false;
      let item = isContinue ? itemNow.value : itemOld.value;
      if (!item) {
        let text = t('message.noVersion');
        alertMessage('warning', text);
        return;
      }
      let data = getData(item);
      download(data);
    }
    return {
      list,
      dialogVisible,
      descriptionContentRef,
      //function
      getHtml,
      ellipsisFn,
      clickDownLoad,
      downloadContinue
    };
  }
};